import React from 'react';
import cN from 'classnames';
import { isEqual } from 'lodash';
import s from './style.module';
import Filter from '../Filter';
import { t } from '../helpers';

/**
 * FilterRadioButtonGroup implements a filter with a group of radio buttons. It notifies the parent component
 * via a callback when the user changes the selection and/or closes the popup.
 * 
 * props:
 * 
 * - activeLabel (string): In ACTIVE state, the filter button will have the label
 * "{activeLabel}: {short radio button label (see below)}".
 * 
 * - radioButtons: An array of objects of the form
 *     {
 *         key: "key",
 *         label: {
 *             long: "Long radio button label",
 *             short: "Short label"
 *         }
 *     }.
 * The long label is displayed in the popup, the short label on the filter button when the radio button is
 * selected (see activeLabel).
 * 
 * - defaultValue (string): The key of the checkbox that is checked when selectedRadioButton is not set.
 * 
 * - icon: inline SVG
 * 
 * - inactiveLabel (string): Label of the filter in state != ACTIVE
 * 
 * - onChange: Function with one argument (value). function is called when the user changes the selected
 * radio button. value is the key of the selected radio button.
 * 
 * - onConfirm: Function with one argument (value). function is called when the user confirms the selection
 * or closes the popup. value is the key of the selected radio button.
 * 
 * - slectedRadioButton: The key of the radio button that is currently checked. If this prop is set, the
 * filter is in ACTIVE state. To set a default value for INACTIVE state, set defaultValue.
 */
class FilterRadioButtonGroup extends React.Component {
  constructor(props) {
    super(props);
    this.bindMethodsToThis();
    this.filterRef = React.createRef();
    this.slectedRadioButton = this.props.slectedRadioButton;
    this.state = {
      label: this.getLabel(),
      checked: this.slectedRadioButton
    };
  }

  bindMethodsToThis() {
    this.closePopup = this.closePopup.bind(this);
    this.onPopupClose = this.onPopupClose.bind(this);
    this.onRadioButtonChange = this.onRadioButtonChange.bind(this);
    this.resetAndClose = this.resetAndClose.bind(this);
  }

  getLabel() {
    if (this.slectedRadioButton) {
      const activeLabelShort = this.props.radioButtons.filter(rb => rb['key'] === this.slectedRadioButton)[0]['label']['short'];
      return `${this.props.activeLabel}: ${activeLabelShort}`;
    } else {
      return this.props.inactiveLabel;
    }
  }

  /**
   * closePopup() calls the closePopup method of the Filter component, which triggers
   * onPopupClose as callback. (The reason for this is that the Filter component may close
   * the popup itself.) Hence any code that must be executed when the popup is closed
   * should be placed in onPopupClose.
   */
  closePopup() {
    this.filterRef.current.closePopup();
  }

  resetAndClose() {
    this.setState({checked: null}, function() { this.closePopup(); });
  }

  /**
   * onPopupClose is called by Filter component when the popup is closed
   * Cf. closePopup().
   */
  onPopupClose() {
    if (this.props.onConfirm && !isEqual(this.slectedRadioButton, this.state.checked)) {
      this.props.onConfirm.call(this, this.state.checked);
      this.slectedRadioButton = this.state.checked;
      this.setState({
        label: this.getLabel()
      });
    }
  }

  onRadioButtonChange(event) {
    const clickedRadioButton = event.target.value;
    
    if (this.props.onChange) {
      this.props.onChange.call(this, clickedRadioButton);
    }

    this.setState({
      checked: clickedRadioButton
    });
  }

  radioButtonChecked(key) {
    if (this.state.checked) {
      return key === this.state.checked;
    } else {
      return key === this.props.defaultValue;
    }
  }

  render() {
    return (
      <Filter
        icon={this.props.icon}
        label={this.state.label}
        filterButtonActive={this.slectedRadioButton}
        ref={this.filterRef}
        onPopupClose={this.onPopupClose}
        onPopupOpen={this.onPopupOpen}>

        {this.props.radioButtons.map((radiobutton) => (
          <label key={radiobutton['key']} className={s['label']}>
            <input type="radio" className={s['radio']} value={radiobutton['key']} checked={this.radioButtonChecked(radiobutton['key'])} onChange={this.onRadioButtonChange} />
            {radiobutton['label']['long']}
          </label>
        ))}
        
        <button className={cN(`btn`, `btn-primary`, s.reset, s['reset-confirm-button'])} onClick={this.closePopup}>{ this.props.confirmLabel || t('shared.confirm') }</button>
        <button className={cN(`btn`, `btn-secondary`, s['reset-confirm-button'])} onClick={this.resetAndClose}>{ t('shared.reset') }</button>

      </Filter>
    );
  }
}

export default FilterRadioButtonGroup;
