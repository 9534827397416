import React from 'react';
import cN from 'classnames';
import { find } from 'lodash';
import s from './style.module';
import blubsch from '!svg-inline-loader!images/icons/blubsch_bold.svg';
import ResponsiveImage from '../ResponsiveImage';
import { CARD_IMAGE_SIZES } from '../helpers';

function Donation({
  title,
  organisation_name,
  organisation_logo,
  images,
  description,
  link,
  current_amount,
  currentAmountLabel,
}) {
  const headerImage = find(images, image => {
      return image.url !== null;
  });

  return (
    <div className={cN(`component-donation`, s.donation)}>
      <a href={link} className={cN(`component-donation__link`, s.link)}>
        {headerImage ? (
          <div className={s.header}>
            <ResponsiveImage
              className={s.headerImage}
              sizes={CARD_IMAGE_SIZES}
              image={headerImage}
              alt=''
            />
          </div>
        ) : (
          <div className={s.header} />
        )}

        {organisation_logo && organisation_logo['w400'] && organisation_logo['w400'].url && (
          <div className={s.logoContainer}>
            <div className={s.logoBlubsch} dangerouslySetInnerHTML={{ __html: blubsch }} />
            <div className={s.logoWrapper}>
              {/* The CSS class logoContainer has a fixed with of 7rem, i.e. 112px. So we don't
              need the ResponsiveImage component here. */}
              <img
                className={s.logo}
                src={organisation_logo['w400'].url}
                alt=''
              />
            </div>
          </div>
        )}

        <div className={s.content}>
          <h4 className={s.organisation}>{organisation_name}</h4>
          <h3 className={s.title}>{title}</h3>
          <p>{currentAmountLabel + ': ' + current_amount}</p>
        </div>
      </a>
    </div>
  );
}

export default Donation;
