import React from 'react';
import FilterBar from '../FilterBar';
import FilterCheckboxGroup from '../FilterCheckboxGroup';
import FilterDateRange from '../FilterDateRange';
import { setLocale, i18n, t, setQueryParams } from '../helpers'
import companyIcon from '!svg-inline-loader!images/icons/filter_company.svg';

export default class FilterBarVolunteeringDates extends React.Component {
  constructor(props) {
    super(props);
    setLocale(this.props.locale);
    this.bindMethodsToThis();

    // we also have this issue elsewhere: flatpickr doesn't allow you to pick a date if it's
    // the current date and one of maxDate and minDate is (...roughly?) equal to the current time.
    // however, it does accept this value. 'today' means 'today, 0 o'clock in the user's
    // timezone' which is fine for us.
    this.pickerMinDate = 'today';
  }

  bindMethodsToThis() {
    this.onDateRangeConfirm = this.onDateRangeConfirm.bind(this);
    this.onCompaniesConfirm = this.onCompaniesConfirm.bind(this);
  }

  onCompaniesConfirm(values) {
    setQueryParams({'selected_companies': values});
  }

  onDateRangeConfirm(fromDate, toDate) {
    const fromDateStr = fromDate ? i18n.l('time.formats.date_technical', fromDate) : null;
    const toDateStr = toDate ? i18n.l('time.formats.date_technical', toDate) : null;
    setQueryParams({ 'from_date': fromDateStr, 'to_date': toDateStr });
  }

  render() {
    return (
      <FilterBar>
        <FilterDateRange
          onDateRangeConfirm={this.onDateRangeConfirm}
          defaultFromDate={this.props.defaultFromDate}
          defaultToDate={this.props.defaultToDate}
          inactiveLabel={t('volunteering_dates_filters.only_future')}
          pickerOptions={{ minDate: this.pickerMinDate }}
        />
        { this.props.allCompanies && this.props.allCompanies.length > 0 && (
          <FilterCheckboxGroup
            inactiveLabel={t('statistics_filters.all_companies')}
            activeLabel={t('statistics_filters.companies')}
            icon={companyIcon}
            checkboxes={this.props.allCompanies}
            defaultValue={this.props.selectedCompanies || []}
            onConfirm={this.onCompaniesConfirm}
          />
        )}
      </FilterBar>
   );
  }
}
