import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="event"
export default class extends Controller {
  static targets = [ 'element' ]
  static values = {
    name: String
  }
  
  connect() {
  }

  click() {
    // Calling click() has not necessarily the same effect as dispatching a click event. Submitting
    // forms is - at least in Firefox - not possible by dispatching a click event on the submit
    // button but it works with [submitButton].click().
    this.eventTarget().click();
  }

  dispatchEvent(event) {
    this.eventTarget().dispatchEvent(
      new CustomEvent(
        this.eventName(event),
        {
          detail: {},
          bubbles: true,
          cancelable: true
        }
      )
    );
  }

  preventDefault(e) {
    e.preventDefault();
  }

  eventTarget() {
    return this.hasElementTarget ? this.elementTarget : this.element;
  }

  eventName(event) {
    return event.params['name'] || this.nameValue;
  }
}
