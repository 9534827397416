import React from 'react';
import flatpickr from 'flatpickr';
import { German } from 'flatpickr/dist/l10n/de.js';
import English from 'flatpickr/dist/l10n/default.js';
import s from './style.module';
import { canUseDOM, getCurrentLanguage } from '../helpers';

if (canUseDOM()) {
  require('flatpickr/dist/flatpickr.css');
}

class DateRangePicker extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.createDatePicker(this.pickerEl);
  }

  createDatePicker() {
    English.firstDayOfWeek = 1;
    let pickerOptions = {
      inline: true,
      mode: 'range',
      defaultDate: this.parseDates(this.props.value),
      onChange: this.props.onDatePickerChange,
      locale: getCurrentLanguage() === 'de' ? German : English,
    };
    if (this.props.pickerOptions) {
      Object.assign(pickerOptions, this.props.pickerOptions);
    }
    this.flatpickr = new flatpickr(this.pickerEl, pickerOptions);
  }

  parseDates(dates) {
    return dates ? [new Date(dates[0]), new Date(dates[1])] : undefined;
  }

  render() {
    return (
      <div className={s.container}>
        <input type="date" ref={c => (this.pickerEl = c)} className={s.input} />
      </div>
    );
  }
}

export default DateRangePicker;
