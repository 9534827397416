import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkboxes"
export default class extends Controller {
  static targets = [ 'checkbox', 'allCheckbox' ]

  connect() {
    this.setAllCheckbox();
  }

  isOneChecked() {
    let allCheckedTargets = this.checkboxTargets.filter(checkbox => checkbox.checked);
    if (allCheckedTargets.length) {
      this.dispatch('one-checked');
    } else {
      this.dispatch('none-checked');
    }
  }

  setCheckboxes() {
    if (this.hasAllCheckboxTarget) {
      this.checkboxTargets.forEach(checkbox => {
        checkbox.checked = this.allCheckboxTarget.checked
      });
    }
  }

  setAllCheckbox() {
    if (this.hasAllCheckboxTarget) {
      let unchecked = this.checkboxTargets.filter(checkbox => !checkbox.checked);
      this.allCheckboxTarget.checked = !unchecked.length;
    }
  }
}
