import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="trix-toolbar"
export default class extends Controller {
  static targets = [ 'linkUrl' ]
  
  prependLinkUrlProtocol() {
    if (this.hasLinkUrlTarget) {
      const valueTrimmed = this.linkUrlTarget.value.trim();
      const valueLowered = valueTrimmed.toLowerCase();
      if (!valueLowered.match('^[a-z]+://') && !valueLowered.startsWith('mailto:')) {
        // The following e-mail regex is far from perfect. It only serves the purpose of guessing
        // whether the protocol should be 'mailto:'.
        const simpleEmailRegex = /^[^\s@\/]+@[^\s@\/]+\.[^\s@\/]+$/;
        const guessedProtocol = valueLowered.match(simpleEmailRegex) ? 'mailto:' : 'https://';
        this.linkUrlTarget.value = guessedProtocol + valueTrimmed;
      } else {
        this.linkUrlTarget.value = valueTrimmed;
      }
    }
  }
}
