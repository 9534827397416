import React from 'react';
import s from './style.module';

class FilterBar extends React.Component {
  render() {
    return (
      <div className={s['filter-bar']}>
        {this.props.children}
      </div>
    );
  }
}

export default FilterBar;
