import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="radio-button"
export default class extends Controller {
  static targets = ['input'];
  static values = { trackedValue: String };

  inputChecked(e) {
    if (e.target.value === this.trackedValueValue) {
      this.dispatch('checked');
    } else {
      this.dispatch('unchecked');
    }
  }
}
