import { getCurrentLanguage } from './../../helpers';

get.operation = 'READ';
get.idFrom = 'ARGS';

function get(query) {
    return fetch(query, {
        method: 'GET',
        headers: new Headers({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }),
    }).then(response => {
        if (response.ok) {
            return response.json();
        } else {
            throw new Error('Something went wrong ...', response);
        }
        return response.json();
    });
}

export { get };
