import { Controller } from "@hotwired/stimulus"

const TARGET_FORMAT = 'image/jpeg';
const TARGET_EXTENSION = '.jpeg';
const ONE_KB = 1024;

// Connects to data-controller="image-conversion"
export default class extends Controller {
  static values = {
    minAspectRatio: Number,
    maxAspectRatio: Number,
    targetFileSize: { type: Number, default: 2048 * ONE_KB },
    targetWidth: { type: Number, default: 2600 }
  };

  /**
   * compress takes this.element.files and:
   * 
   * 1. crops them so that their aspect ratio is greater than or equal to minAspectRatio (if
   *    present) and less than or qual to maxAspectRatio (if present),
   * 2. scales them so their width is less than or equal to targetWidth,
   * 3. compresses them so their file size is around targetFileSize.
   * 
   * The result is written to this.element.files. Afterwards, a image-conversion:compressed event is
   * dispatched.
   */
  compress() {
    let compressPromises = [];
    for (let i = 0; i < this.element.files.length; i++) {
      compressPromises.push(this.compressPromise(this.element.files.item(i)));
    }

    Promise.all(compressPromises).then(
      function(compressedFiles) {
        // Assign compressed files to the file input FileList (this.element.files).
        //
        // Since FileList is read-only, we construct a DataTransfer object and assign its files
        // object to this.element.files.
        let compressedFilesDataTransfer = new DataTransfer();
        for (let file of compressedFiles) {
          compressedFilesDataTransfer.items.add(file);
        }
        this.element.files = compressedFilesDataTransfer.files;

        this.dispatch('compressed');
      }.bind(this),
      function() {
        // Fallback on error: Dispatch the compressed event anyway. this.element.files is not
        // changed but we still have the original files and can try to upload those. The user
        // might see a validation error because the server does not accept the mime type/extension
        // of the original file. But at least the user will get some feedback.
        this.dispatch('compressed');
      }.bind(this)
    );
  }

  loadFileIntoImage(file) {
    return new Promise(function(resolve) {
      let rawImage = new Image();

      rawImage.addEventListener("load", function () {
        resolve(rawImage);
      });

      rawImage.src = URL.createObjectURL(file);
    });
  }

  drawImageToCanvas(image) {
    // Determine the section of the image that should be drawn based on its aspect ratio and the
    // values minAspectRatio and maxAspectRatio.

    const aspectRatio = image.width/image.height;
    // For a visual explanation of sx, sy, sWidth and sHeight see
    // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage
    let sx, sy, sWidth, sHeight;

    if (this.hasMinAspectRatioValue && aspectRatio < this.minAspectRatioValue) {
      // Height is too large compared to width - crop top and bottom by a total of cropTotal so that
      // the new aspect ratio is minAspectRatio:
      //
      //   width / (height - cropTotal) = minAspectRatio
      //   <=> cropTotal = height - width / minAspectRatio.
      const cropTotal = image.height - image.width / this.minAspectRatioValue;
      sx = 0;
      sy = cropTotal / 2;
      sWidth = image.width;
      sHeight = image.height - cropTotal;
    } else if (this.hasMaxAspectRatioValue && aspectRatio > this.maxAspectRatioValue) {
      // Width is too large compared to height - crop left and right by a total of cropTotal so that
      // the the aspect ratio is maxAspectRatio:
      //
      //   (width - cropTotal) / height = maxAspectRatio
      //   <=> cropTotal = width - maxAspectRatio * height.
      const cropTotal = image.width - this.maxAspectRatioValue * image.height;
      sx = cropTotal / 2;
      sy = 0;
      sWidth = image.width - cropTotal;
      sHeight = image.height;
    } else {
      // Don't crop at all.
      sx = 0;
      sy = 0;
      sWidth = image.width;
      sHeight = image.height;
    }

    // Determine width and height of the canvas based on the width and height of the image section
    // to draw and targetWidth.

    let dWidth = sWidth;
    let dHeight = sHeight;

    if (dWidth > this.targetWidthValue) {
      const scaleFactor = this.targetWidthValue / dWidth;
      dWidth = dWidth * scaleFactor;
      dHeight = dHeight * scaleFactor;
    }

    // Draw!

    let canvas = document.createElement('canvas');

    canvas.width = dWidth;
    canvas.height = dHeight;
    
    canvas.getContext("2d").drawImage(
      image,
      // Draw the rectengular section between (sx, sy) and (sx + sWidth), (sy + sHeight):
      sx, sy, sWidth, sHeight,
      // Fill the canvas completely with this section:
      0, 0, dWidth, dHeight
    );
    
    return canvas;
  }

  exportCanvasToBlob(canvas, quality) {
    return new Promise(function(resolve) {
      canvas.toBlob(
        function(blob) {
          resolve(blob);
        },
        TARGET_FORMAT,
        quality
      );
    });
  }
  
  async compressPromise(file) {
    // - Let the server sort out non-image files and display a validation error.
    // - Don't try to convert SVG images.
    if (!file.type.startsWith('image/') || file.type == 'image/svg+xml') {
      return file;
    }

    // The file is an image and should be uploaded with a filename with the extension
    // TARGET_EXTENSION. Save a filename with this extension in convertedFilename.
    let filenameParts = file.name.split('.');
    if (filenameParts.length > 1) {
      filenameParts.pop();
    }
    const convertedFilename = filenameParts.join('.') + TARGET_EXTENSION;

    // Don't do anything except possibly renaming the file if we already have a small enough file in
    // the right format.
    if (file.type == TARGET_FORMAT && file.size <= this.targetFileSizeValue) {
      return new File(
        [file],
        convertedFilename,
        { type: file.type }
      );
    }

    const image = await this.loadFileIntoImage(file);
    const canvas = this.drawImageToCanvas(image);
    
    // Now we choose the quality parameter so that the resulting file size is near targetFileSize.
    // The algorithm for approaching the target size is taken from the npm package
    // 'image-conversion', function 'compressAccurately':
    // https://github.com/WangYuLue/image-conversion/blob/6f2242362ae56a0dc342931973b9c5812b568c45/src/index.ts#L84
    let quality = 0.5;

    const maxSize = this.targetFileSizeValue * 1.05;
    const minSize = this.targetFileSizeValue * 0.95;
    
    let blob;

    for (let x = 1; x <= 7; x++) {
      blob = await this.exportCanvasToBlob(canvas, quality);
      const size = blob.size;
      if (size > maxSize) {
        quality -= 0.5 ** (x + 1);
      } else if (size < minSize) {
        quality += 0.5 ** (x + 1);
      } else {
        break;
      }
    }

    return new File([blob], convertedFilename, { type: blob.type });
  }
}
