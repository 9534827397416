import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="screen"
export default class extends Controller {
  connect() {
  }

  checkSize() {
    if (window.innerWidth < 992) {
      this.dispatch('mobile');
    } else {
      this.dispatch('desktop');
    }
  }
}
