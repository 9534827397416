import React from 'react';
import Item from './Item';
import { map } from 'lodash';
import cN from 'classnames';
import s from './style.module';


export default class Menu extends React.Component {

  constructor(props) {
    super(props);
    this.menuItems = props.menuItems;
  }

  render() {
    return (
        <div className={cN(s.container, s['container--' + this.menuItems.length + '-items'])}>
            {map(this.menuItems, (item, idx) => {
                return <Item key={idx} {...item} />;
            })}
        </div>
    );
  }
}
