import { build } from 'ladda-cache';

import * as projects from './projects.jsx';

const config = {
    projects: {
        ttl: 3000,
        api: projects,
    },
};

const api = build(config);

export default api;
