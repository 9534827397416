import React from 'react';
import { isEqual } from 'lodash';
import FilterBar from '../FilterBar';
import FilterCheckboxGroup from '../FilterCheckboxGroup';
import FilterRadioButtonGroup from '../FilterRadioButtonGroup';
import { setLocale, t, setQueryParams } from '../helpers'

class FilterBarUsers extends React.Component {
  constructor(props) {
    super(props);
    setLocale(this.props.locale);
    this.bindMethodsToThis();
  }

  bindMethodsToThis() {
    this.onCompaniesConfirm = this.onCompaniesConfirm.bind(this);
    this.onRolesConfirm = this.onRolesConfirm.bind(this);
  }

  onlyPotentialCorporateRoles(roles) {
    return isEqual(roles, [ 'Volunteer' ]);
  }

  yes_no_radio_buttons() {
    return [
      {
        key: "yes",
        label: {
          long: t('users_filters.answer_yes'),
          short: t('users_filters.answer_yes')
        }
      },
      {
        key: "no",
        label: {
          long: t('users_filters.answer_no'),
          short: t('users_filters.answer_no')
        }
      }
    ];
  }

  onRolesConfirm(values) {
    let params = {'roles': values};
    if (!this.onlyPotentialCorporateRoles(values)) {
      // Delete the companies selection
      params['companies'] = [null];
    }
    setQueryParams(params);
  }

  onCompaniesConfirm(values) {
    setQueryParams({'companies': values});
  }

  onAdditionalPermissionsConfirm(value) {
    setQueryParams({'additional_permissions': value});
  }

  render() {
    return (
      <FilterBar>
        <FilterCheckboxGroup
          inactiveLabel={t('users_filters.all_roles')}
          activeLabel={t('users_filters.roles')}
          checkboxes={this.props.allRoles}
          defaultValue={this.props.selectedRoles || []}
          onConfirm={this.onRolesConfirm}
        />
        <FilterRadioButtonGroup
          inactiveLabel={t('users_filters.additional_permissions')}
          activeLabel={t('users_filters.additional_permissions')}
          radioButtons={this.yes_no_radio_buttons()}
          slectedRadioButton={this.props.additionalPermissions}
          onConfirm={this.onAdditionalPermissionsConfirm}
        />
        { this.onlyPotentialCorporateRoles(this.props.selectedRoles) &&
          <FilterCheckboxGroup
            inactiveLabel={t('users_filters.all_companies')}
            activeLabel={t('users_filters.companies')}
            checkboxes={this.props.allCompanies}
            defaultValue={this.props.selectedCompanies || []}
            onConfirm={this.onCompaniesConfirm}
          />
        }
      </FilterBar>
    );
  }
}

export default FilterBarUsers;
