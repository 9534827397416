import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="expansion-panel"
// Does some really simple stuff, but unfortunately you can't use the CSS controller to replace
// it because the CSS controller doesn't support multiple classes. Hacking the CSS controller to
// be extensible would have been more work.
export default class extends Controller {
  static targets = ['button', 'content'];

  static touchDetected = false;

  // The correct thing to do is obviously to write a static block, but those are fairly new and I
  // actually don't know whether our babel config would transpile them. I don't care to spend the
  // time to find out right now either; manually doing the transformation
  // (https://babeljs.io/docs/babel-plugin-transform-class-static-block) is faster.
  static block = (() =>
    document.addEventListener(
      'touchstart',
      () => { this.touchDetected = true },
      { once: true }
    )
  )();

  addHoveredButton() {
    if (this.constructor.touchDetected) return;
    this.element.classList.add('hovered-button');
  }

  // I deliberately wrote two functions rather than a single toggleHoveredButton function
  // because with the single function you could, in rare circumstances and when reloading the
  // page with your mouse inside an expansion panel header, have an expansion panel acquire the
  // class when it shouldn't.

  removeHoveredButton() {
    if (this.constructor.touchDetected) return;
    this.element.classList.remove('hovered-button');
  }

  toggleOpen() {
    let wasExpanded = this.buttonTarget.ariaExpanded === 'true';
    this.buttonTarget.ariaExpanded = wasExpanded ? 'false' : 'true';
    this.contentTarget.style.display = wasExpanded ? 'none' : 'block';
  }
}
