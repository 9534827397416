import React from 'react';
import { map } from 'lodash';
import s from './style.module';
import Donation from 'components/Donation';

class DonationList extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { donations, currentAmountLabel } = this.props;
        return (
            <ul className={s.container}>
                {map(donations, (donation, idx) => (
                    <li className={s.donation} key={idx}>
                        <Donation {...donation} currentAmountLabel={currentAmountLabel} />
                    </li>
                ))}
            </ul>
        );
    }
}

export default DonationList;
