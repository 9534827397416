import React from 'react';
import { map } from 'lodash';
import cN from 'classnames';
import s from './style.module';

class Item extends React.Component {
  constructor(props) {
    super(props);
    this.mouseEnterLink = this.mouseEnterLink.bind(this);
    this.mouseEnterFlyout = this.mouseEnterFlyout.bind(this);
    this.mouseLeave = this.mouseLeave.bind(this);
    this.toggleFlyout = this.toggleFlyout.bind(this);
    window.addEventListener('resize', this.calculateResize.bind(this));
    this.state = {};
  }

  componentDidMount() {
    if (this.childrenContainerElement) {
      this.updateContainerState();
    }
  }

  calculateResize() {
    if (this.childrenContainerElement) {
      this.updateContainerState();
    }
  }

  render() {
    const { href, text, icon, children, active } = this.props;
    return (
      <div
        className={s.item}
        ref={ (elm) => this.itemElement = elm }
      >
        <a
          href={href}
          className={cN(`component-menu__link`, s.link, active || this.state.flyout ? s['link--active'] : null)}
          onMouseEnter={this.mouseEnterLink}
          onMouseLeave={this.mouseLeave}
          onClick={this.toggleFlyout}
        >
          <span className={s.icon} dangerouslySetInnerHTML={{ __html: icon }} />
          {text}
        </a>

        {children && (
          <div
            className={cN(`component-menu__children-container`, s['children-container'], this.state.flyout ? null : s['children-container--hidden'])}
            onMouseEnter={this.mouseEnterFlyout}
            onMouseLeave={this.mouseLeave}
            style={{left: this.state.childrenContainerLeft + 'px'}}
            ref={ (elm) => this.childrenContainerElement = elm }
          >
            <div className={cN(`component-menu__children-container__arrow`, s['children-container__arrow'])} style={{left: this.state.arrayLeft + 'px'}}></div>
            <ul className={s['children-list']}>
              {map(children, (child, idx) => (
                <li className={s.child} key={idx}>
                  <a href={child.href} className={cN(`component-menu__child-link`, s['child-link'], child.active ? s['child-link--active'] : null)} dangerouslySetInnerHTML={{ __html: child.text }} />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }

  updateContainerState() {
    let itemWidth = this.itemElement.offsetWidth;
    let itemLeft = this.itemElement.offsetLeft;
    let childrenContainerWidth = this.childrenContainerElement.offsetWidth;
    let windowWidth = document.documentElement.clientWidth;
    var childrenContainerLeft, arrayLeft;
    if (windowWidth - itemLeft - childrenContainerWidth / 2 - itemWidth / 2 < 0) {
      childrenContainerLeft = windowWidth - childrenContainerWidth - itemLeft - 1;
    } else if (itemLeft - childrenContainerWidth / 2 < 0) {
      childrenContainerLeft = - itemLeft;
    } else {
      childrenContainerLeft = (itemWidth - childrenContainerWidth) / 2;
    }
    arrayLeft = - childrenContainerLeft + itemWidth / 2;
    this.setState({
      childrenContainerLeft: childrenContainerLeft,
      arrayLeft: arrayLeft,
    });
  }

  mouseEnterLink() {
    this.disableClick();
    this.setState({
      flyout: true,
    });
  }

  mouseEnterFlyout() {
    this.setState({
      flyout: true,
    });
  }

  mouseLeave() {
    this.setState({
      flyout: false,
    });
  }

  toggleFlyout() {
    if (this.clickDisabled) {
      return;
    }
    this.setState({
      flyout: !this.state.flyout,
    });
  }

  disableClick() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    let that = this;
    that.clickDisabled = true;
    this.timeout = setTimeout(() => {
      that.clickDisabled = false;
    }, 100);
  }
}

export default Item;
