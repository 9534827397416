import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {
  static values = {
    status: String
  }

  connect() {
    if (!this.hasStatusValue) {
      this.statusValue = 'hidden';
    }

    // If the user closes the modal with bootstrap functionality (e.g. by clicking somewhere outside
    // the modal), we must update the status value. We must use hidden.bs.modal instead of
    // hide.bs.modal, which fires too often for some reason.
    $(this.element).on('hidden.bs.modal', function() {
      this.close();
    }.bind(this));
  }

  open() {
    this.statusValue = 'open';
  }

  close() {
    this.statusValue = 'hidden';
  }

  statusValueChanged() {
    if (this.statusValue == 'open') {
      $(this.element).modal();
    } else {
      $(this.element).modal('hide');
    }
  }

  onFormSubmit(event) {
    if (event.detail.success) {
      this.close();
    }
  }
}
