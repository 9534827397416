import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dom"
export default class extends Controller {
  static targets = [ 'node' ]
  
  connect() {
  }

  remove() {
    this.nodeTargets.forEach((node) => node.remove());
  }

  replaceValue(event) {
    this.nodeTargets.forEach((node) => {
      node.value = node.value.replace(
        new RegExp(event.params['regexp'], event.params['regexpFlags']),
        event.params['replaceBy']
      )
    });
  }

  setAttribute(event) {
    this.nodeTargets.forEach(
      (node) => node.setAttribute(event.params['attribute'], event.params['value'])
    );
  }

  removeAttribute(event) {
    this.nodeTargets.forEach(
      (node) => node.removeAttribute(event.params['attribute'])
    );
  }

  setInnerText(event) {
    this.nodeTargets.forEach(
      (node) => node.innerText = event.detail['text']
    );    
  }
}
