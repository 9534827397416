import React from 'react';
import arrow from '!svg-inline-loader!images/icons/arrow_no_shaft.svg';
import s from './style.module';
import { t, setLocale } from '../helpers'

export default class BackToTop extends React.Component {

  constructor(props) {
    super(props);
    this.state = { visible: false };
    setLocale(this.props.locale);
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.scrollChange.bind(this));
      window.addEventListener('resize', this.calculateResize.bind(this));
      window.addEventListener('routeChange', this.calculateResize.bind(this));
      window.addEventListener('routeChange', this.calculateResize.bind(this));
    }
  }

  calculateResize() {
    this.updateVisibility();
  }

  scrollChange() {
    this.updateVisibility();
  }

  updateVisibility() {
    const scrollY = window.scrollY;
    const windowHeight = window.innerHeight;
    this.setState({ visible: scrollY > Math.max(450, windowHeight) });
  }

  render() {
    return (
      <a href="#" className={`${s.link} component-back-to-top__link`}
        aria-label={`${t('back_to_top.aria_label')}`}
        style={{display: this.state.visible ? 'block' : 'none'}}
      >
        <div aria-hidden="true" dangerouslySetInnerHTML={{ __html: arrow }} />
      </a>
    );
  }
}
