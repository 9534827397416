import { Controller } from "@hotwired/stimulus"

/**
 * This controller scrolls the element it is applied to to the bottom if no 'scroll' target is
 * given. Otherwise, it scrolls to the top of the first 'scroll' target.
 * 
 * In order to calculate the correct top offset of the 'scroll' target, the element the controller
 * is applied to must be positioned (e.g. position: relative).
 * See https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/offsetTop
 * 
 * Connects to data-controller="scroll"
 */
export default class extends Controller {
  static targets = [ 'scroll' ];

  connect() {
    let scrollTo;

    if (this.hasScrollTarget) {
      // We assume that margin-top is given in px. Otherwise the following line won't work.
      let targetMarginTop = parseInt(window.getComputedStyle(this.scrollTarget).marginTop);
      scrollTo = this.scrollTarget.offsetTop - targetMarginTop;
    } else {
      scrollTo = this.scope.element.scrollHeight;
    }
    
    this.scope.element.scroll({ top: scrollTo });
  }
}
