import ReactDOM from 'react-dom';
import React from 'react';
import { canUseDOM } from 'components/helpers';

export default class ResultContainer extends React.Component {
    constructor(props) {
        super(props);
        if (canUseDOM()) {
            this.el = document.createElement('div');
            this.root = document.getElementById('searchResult');
        }
    }

    componentDidMount() {
        this.root.appendChild(this.el);
    }

    componentWillUnmount() {
        this.root.removeChild(this.el);
    }

    render() {
        if (canUseDOM()) {
            return ReactDOM.createPortal(this.props.children, this.el);
        }
        return null;
    }
}
