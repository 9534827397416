import React from 'react';
import Item from './Item';
import { map, forEach } from 'lodash';
import cN from 'classnames';
import s from './style.module';

import iconOpen from '!svg-inline-loader!./open.svg';
import iconClosed from '!svg-inline-loader!./closed.svg';
import blubschBackground from '!svg-inline-loader!images/icons/icon_blubsch_solid.svg';

const ORGINAL_PREHEADER_MENU_ID = 'preheader-menu';
const LANGUAGE_SWITCH_CLASS = 'language-switch';

export default class MenuMobile extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
    this.items = [];
  }

  componentDidMount() {
    const preheaderUlElement = document.createElement('ul');
    const languageSwitchUlElement = document.createElement('ul');
    const preheaderMenu = document.getElementById(ORGINAL_PREHEADER_MENU_ID);
    forEach(preheaderMenu.children, (child) => {
      if (child.className == LANGUAGE_SWITCH_CLASS) {
        languageSwitchUlElement.appendChild(child.cloneNode(true));
      } else {
        preheaderUlElement.appendChild(child.cloneNode(true));
      }
    });
    this.languageSwitchWrapperElement.appendChild(languageSwitchUlElement);
    this.preheaderMenuWrapperElement.appendChild(preheaderUlElement);
  }

  render() {
    const { menuItems } = this.props;
    const menuIcon = this.state.isOpen ? iconOpen : iconClosed;
    return (
      <div>
        <button className={s.button} onClick={this.toggle} dangerouslySetInnerHTML={{ __html: menuIcon }} />
        <div className={cN(`component-menu-mobile__overlay`, s.overlay, { [s.open]: this.state.isOpen })}>
          <div className={s['language-switch-wrapper']} ref={elm => (this.languageSwitchWrapperElement = elm)} />
          <div className={s.mainMenu}>
            {map(menuItems, (item, idx) => <Item key={idx} menu={this} icon={item.icon} {...item} />)}
          </div>
          <div className={s['preheader-menu-wrapper']} ref={elm => (this.preheaderMenuWrapperElement = elm)} />
        </div>
      </div>
    );
  }

  registerItem(item) {
    this.items.push(item);
  }

  itemToggled(toggledItem) {
    forEach(this.items, (item) => {
      if (item != toggledItem) {
        item.closeFlyout();
      }
    });
  }

  toggle() {
    this.setState({ isOpen: !this.state.isOpen });
    document.body.classList.toggle(s['prevent-scroll'], !this.state.isOpen);
  }
}
