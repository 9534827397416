import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="turbo"
export default class extends Controller {
  /**
   * When a response to a request from inside a turbo frame doesn't contain a corresponding turbo
   * frame, the event turbo:frame-missing is fired. You can use setWindowLocation to prevent Turbo
   * from trying to render the response inside a turbo frame and instead set the window location
   * to the corresponding URL. This is helpful in case the user session expired and a sign in form
   * must be rendered.
   * 
   * This way of doing it is not particularly efficient because 'window.location = ...' triggers a
   * second request.
   * 
   * @param {TurboFrameMissingEvent} event
   */
  setWindowLocation(event) {
    event.preventDefault();
    window.location = event.detail.response.url;
  }
}
