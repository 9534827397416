import { format } from 'date-fns';
import { I18n } from "i18n-js";
import { isEqual } from 'lodash';
import translations from "../../locales.json"

const DATE_FORMATS = {
  de: 'dd.MM.yyyy',
  en: 'dd/MM/yyyy',
};

const TIME_FORMATS = {
  de: 'HH:mm',
  en: 'hh:mm a',
};

export const i18n = new I18n(translations);

export function t(key) {
  return i18n.t(`js.components.${key}`);
}

export function formatDate(date) {
  return format(date, DATE_FORMATS[getCurrentLanguage()]);
}

export function formatTime(date) {
  return format(date, TIME_FORMATS[getCurrentLanguage()]);
}

export function canUseDOM() {
  return !!(typeof window !== 'undefined' && window.document && window.document.createElement);
}

export function getCurrentLanguage() {
  return canUseDOM() ? document.documentElement.lang : 'en';
}

/**
 * setLocale sets the i18n locale to the parameter or result of getCurrentLanguage().
 * When rendered on the client, we can use the getCurrentLanguage() helper function to determine
 * the current locale. But for server side rendering, we need the locale given by Rails via props.
 * Hence the getCurrentLanguage() way is used as fallback if the locale parameter is == null.
 * @param {string} locale 
 */
export function setLocale(locale) {
  const newLocale = locale || getCurrentLanguage();
  i18n.defaultLocale = newLocale;
  i18n.locale = newLocale;
}

/**
 * alterQueryParams takes the current query string, appends the parameters from params,
 * overwriting existing parameters, and returns the result as a string. Note that the result
 * does *not* start with '?'; you may need to add that.
 * 
 * Examples: If the current query string is empty,
 * alterQueryParams({'companies': [1,2], 'date': '2022-05-01'})
 * returns 'companies%5B%5D=1&companies%5B%5D=2&date=2022-05-01'.
 * If the current query string is 'companies%5B%5D=1&companies%5B%5D=2&date=2022-05-01',
 * alterQueryParams({'companies': [3], 'date': '2022-05-02'})
 * returns  'companies%5B%5D=3&date=2022-05-02'.
 * 
 * Parameters which are present in the query string, but not present in params,
 * will be preserved.
 *
 * To delete an existing non-array parameter, pass null as value:
 *     alterQueryParams({'from_date': null}).
 * To delete an existing array parameter, pass [null] as value:
 *     alterQueryParams({'companies': [null]}).
 * 
 * @param {Object} params 
 */
export function alterQueryParams(params) {
  let arrayToQuery = function(key, values) {
    return values
      .map(value => `${encodeURIComponent(`${key}[]`)}=${encodeURIComponent(value)}`)
      .join('&');
  }
  
  let searchParams = new URLSearchParams(window.location.search);
  let arrayComponents = [];

  for (let key of Object.keys(params)) {
    if (params[key] === null) {
      searchParams.delete(key);
    } else {
      if (Array.isArray(params[key])) {
        searchParams.delete(`${key}[]`);
        if (!isEqual([null], params[key])) {
          arrayComponents.push(arrayToQuery(key, params[key]));
        }
      } else {
        searchParams.set(key, params[key]);
      }
    }
  }

  let query = searchParams.toString();
  if (arrayComponents.length) {
    query += (query.length ? '&' : '') + arrayComponents.join('&')
  }

  return query;
}

/**
* This calls {@link alterQueryParams} with params, then sets window.location.search to the result.
*/
export function setQueryParams(params) {
  window.location.search = alterQueryParams(params);
}

/**
 * This constant is the value of the sizes attribute for cover images in project/donation cards.
 * These cards have a max-width of 400px. So the 400px wide image versions should be enough. On
 * screens with a high pixel density, 700px might be better.
 * 
 * However, the whole sizes/srcset business is a little complicated and I'm not sure its worth the
 * effort. After all, the 700px versions do look better on ordinary desktop screens (with 1dppx
 * pixel density), so we could just use this single version.
 * 
 * But for now, I want to imitate the current behavior on desktop screens, which loads the 400px
 * version.
 * 
 * Browsers take into account the resolution when evaluating the sizes/srcset attributes in the 
 * following way: If we specify a width of 350px and the screen has a pixel density of 1dppx, the
 * browser chooses the 350px wide image. But if the pixel density is 2dppx, the browser chooses the
 * 700px wide image. And so on.
 * 
 * If we only set sizes="400px", then we load the 1300px or even 2600px versions on most mobile
 * screens.
 * 
 * The following value of sizes has the effect that we load the 400px version on screens with a
 * 1dppx pixel density (i.e. desktop screens) and the 700px version on screens with a pixel density
 * of 2dppx or 3dppx. Above that, larger images are loaded, but  there probably aren't a lot of
 * devices with such high-resolution displays.
 */
export const CARD_IMAGE_SIZES = '(max-resolution: 1dppx) 400px, (max-resolution: 2dppx) 350px, 233px';
