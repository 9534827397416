import React from 'react';
import s from './style.module';
import cN from 'classnames';
import TransparentButton, { transparentButtonState } from '../TransparentButton';
import closeIcon from '!svg-inline-loader!images/icons/close.svg';
import { canUseDOM } from '../helpers';

class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.bindMethodsToThis();
    this.state = {
      showPopup: false,
    };
  }

  bindMethodsToThis() {
    this.closePopup = this.closePopup.bind(this);
    this.onFilterButtonClick = this.onFilterButtonClick.bind(this);
    this.onWindowClick = this.onWindowClick.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
  }
  
  openPopup() {
    this.setState({ showPopup: true });
    if (this.props.onPopupOpen) {
      this.props.onPopupOpen.call();
    }
  }
  
  closePopup() {
    this.setState({ showPopup: false });
    if (this.props.onPopupClose) {
      this.props.onPopupClose.call();
    }
  }

  togglePopup() {
    if (this.state.showPopup) {
      this.closePopup();
    } else {
      this.openPopup();
    }
  }

  popupStyle() {
    return {
      display: this.state.showPopup ? 'block' : 'none'
    };
  }

  onFilterButtonClick(event) {
    this.filterButtonClicked = true;
    this.togglePopup();
  }

  stopEventPropagation(event) {
    // When the user clicks anywhere, normally the click handlers of window
    // would be called. Since we registered this.closePopup as handler, this would
    // close the popup. This should not happen when the user clicks inside the
    // popup. So we call stopPropagation() in this case.
    event.stopPropagation();
  }

  filterButtonState() {
    if (this.props.filterButtonActive) {
      return transparentButtonState.ACTIVE;
    } else if (this.state.showPopup) {
      return transparentButtonState.OPEN;
    } else {
      return transparentButtonState.DEFAULT;
    }
  }

  onWindowClick(event) {
    // Close the popup unless is was just opened because the user clicked on *this* filter button.
    if (!this.filterButtonClicked) {
      this.closePopup();
    } else {
      this.filterButtonClicked = false;
    }
  }

  manageWindowClickHandler() {
    if (!canUseDOM()) {
      // Server side rendering - listener will be added as soon as the component is rendered at the client.
      return;
    }
    if (this.state.showPopup) {
      // Close the popup if the user clicks somewhere outside (see stopEventPropagation).
      window.addEventListener('click', this.onWindowClick);
    } else {
      window.removeEventListener('click', this.onWindowClick);
    }
  }

  render() {
    this.manageWindowClickHandler();

    return (
      <div className={s['filter']}>
        <TransparentButton icon={this.props.icon} onClick={this.onFilterButtonClick} className={s['filter-button']} state={this.filterButtonState()}>
          { this.props.label }
        </TransparentButton>
        <div className={cN(s['popup__overlay'], this.state.showPopup ? s['popup__overlay--active'] : null)}></div>
        <div className={s['popup']} style={this.popupStyle()} onClick={this.stopEventPropagation}>
          <span className={s['popup__close-button']} dangerouslySetInnerHTML={{ __html: closeIcon }} onClick={this.closePopup} />
          { this.props.children }
        </div>
      </div>
    );
  }
}

export default Filter;
