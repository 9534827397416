import { Controller } from "@hotwired/stimulus";
import { alterQueryParams } from "../components/helpers";

// Connects to data-controller="query-parameters"
export default class extends Controller {
  static values = { params: Object };

  replaceParams() {
    let newSearchParams = alterQueryParams(this.paramsValue);
    history.replaceState(null, '', '?' + newSearchParams);
  }
}
