import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="date-form"
export default class extends Controller {
  static targets = [
    'date',
    'startTime',
    'endTime',
    'nextDayNotice',
    'emailNotificationNotice',
    'repetitionInterval',
    'endOfRepetition'
  ];

  static values = {
    initialDate: String,
    initialStartTime: String,
    initialEndTime: String
  }

  connect() {
    this.setNextDayNoticeVisibility();
    this.setEndOfRepetitionDisabled();

    // This is ugly. I'd like to add an action to the html template. But 'changeDate' is a jquery
    // event and it is not possible to listen to jquery events with vanilla JS event listeners.
    $(document.getElementById('date_start_date_group')).on(
      'changeDate',
      this.setEmailNotificationNoticeVisibility.bind(this)
    );
  }

  setNextDayNoticeVisibility() {
    let startTime = this.timeStringToDate(this.startTimeTarget.value);
    let endTime = this.timeStringToDate(this.endTimeTarget.value);

    if (startTime && endTime && startTime > endTime) {
      this.nextDayNoticeTarget.classList.remove('display-none');
    } else {
      this.nextDayNoticeTarget.classList.add('display-none');
    }
  }

  setEmailNotificationNoticeVisibility() {
    if (!this.hasEmailNotificationNoticeTarget) {
      return;
    }

    if (
      (this.hasDateTarget && this.hasInitialDateValue && this.dateTarget.value != this.initialDateValue) ||
      (this.hasStartTimeTarget && this.hasInitialStartTimeValue && this.startTimeTarget.value != this.initialStartTimeValue) ||
      (this.hasEndTimeTarget && this.hasInitialEndTimeValue && this.endTimeTarget.value != this.initialEndTimeValue)
    ) {
      this.emailNotificationNoticeTarget.classList.remove('display-none');
    } else {
      this.emailNotificationNoticeTarget.classList.add('display-none');
    }
  }

  setEndOfRepetitionDisabled() {
    if (this.hasRepetitionIntervalTarget && this.hasEndOfRepetitionTarget) {
      if (this.repetitionIntervalTarget.value == '') {
        this.endOfRepetitionTarget.setAttribute('disabled', '');
      } else {
        this.endOfRepetitionTarget.removeAttribute('disabled');
      }
    }
  }

  timeStringToDate(timeString) {
    let parts = timeString.split(':');
    if (parts.length < 2) {
      parts = timeString.split('.');
    }
    if (parts.length == 2) {
      return new Date(2023, 12, 11, parts[0], parts[1]);
    } else {
      return new Date(2023, 12, 11, parts[0], 0);
    }
  }
}
