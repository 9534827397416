import React from 'react';
import cN from 'classnames';
import { isEqual } from 'lodash';
import s from './style.module';
import Filter from '../Filter';
import { t } from '../helpers';

/**
 * FilterCheckboxGroup implements a filter with a group of checkboxes. It notifies the parent component
 * via a callback when the user changes the selection and/or closes the popup.
 * 
 * props:
 * 
 * - activeLabel (string, optional): In ACTIVE state, the filter will have the label "{activeLabel} {n}", where
 * n is the number of checked checkboxes. If activeLabel is not set, the filter will have the label
 * "{inactiveLabel} {n}" in the ACTIVE state. 
 * 
 * - checkboxes: An array of objects of the form { label: "Checkbox label", key: "key" }.
 * 
 * - defaultValue: An array of keys of the checkboxes that are checked by default.
 * 
 * - icon: inline SVG
 * 
 * - inactiveLabel (string): Label of the filter in state != ACTIVE
 * 
 * - onChange: Function with one argument (value). function is called when the user selects or unselects a
 * checkbox. value is an array of the keys of checked checkboxes.
 * 
 * - onConfirm: Function with one argument (value). function is called when the user confirms selection or closes
 * the popup. value is an array of the keys of checked checkboxes.
 */
class FilterCheckboxGroup extends React.Component {
  constructor(props) {
    super(props);
    this.bindMethodsToThis();
    this.filterRef = React.createRef();
    this.defaultValue = this.props.defaultValue;
    this.state = {
      label: this.getLabel(this.defaultValue.length),
      checked: [...this.defaultValue]
    };
  }

  bindMethodsToThis() {
    this.closePopup = this.closePopup.bind(this);
    this.onCheckboxClick = this.onCheckboxClick.bind(this);
    this.onPopupClose = this.onPopupClose.bind(this);
    this.resetAndClose = this.resetAndClose.bind(this);
  }

  getLabel(numOfSelectedCheckboxes) {
    return numOfSelectedCheckboxes ? `${this.props.activeLabel || this.props.inactiveLabel} (${numOfSelectedCheckboxes})` : this.props.inactiveLabel
  }

  /**
   * closePopup() calls the closePopup method of the Filter component, which triggers
   * onPopupClose as callback. (The reason for this is that the Filter component may close
   * the popup itself.) Hence any code that must be executed when the popup is closed
   * should be placed in onPopupClose.
   */
  closePopup() {
    this.filterRef.current.closePopup();
  }

  resetAndClose() {
    this.setState({checked: []}, function() { this.closePopup(); });
  }

  /**
   * onPopupClose is called by Filter component when the popup is closed
   * Cf. closePopup().
   */
  onPopupClose() {
    if (this.props.onConfirm && !isEqual(this.defaultValue, this.state.checked)) {
      this.props.onConfirm.call(this, this.state.checked);
      this.defaultValue = [...this.state.checked];
      this.setState({
        label: this.getLabel(this.state.checked.length)
      });
    }
  }

  onCheckboxClick(event) {
    const checked = this.state.checked;
    const clickedCheckbox = event.target.value;
    const indexOfClickedCheckbox = checked.indexOf(clickedCheckbox);

    if (indexOfClickedCheckbox == -1) {
      // checkbox was selected
      checked.push(clickedCheckbox);  
    } else {
      // checkbox was unselected
      checked.splice(indexOfClickedCheckbox, 1);
    }

    if (this.props.onChange) {
      this.props.onChange.call(this, checked);
    }

    this.setState({
      checked: [...checked]
    });
  }

  render() {
    return (
      <Filter
        icon={this.props.icon}
        label={this.state.label}
        filterButtonActive={this.defaultValue.length}
        ref={this.filterRef}
        onPopupClose={this.onPopupClose}
        onPopupOpen={this.onPopupOpen}>

        {this.props.checkboxes.map((checkbox) => (
          <label key={checkbox['key']} className={s.label}>
            <input type="checkbox" className={s.checkbox} value={checkbox['key']} checked={this.state.checked.indexOf(checkbox['key']) != -1} onChange={this.onCheckboxClick} />
            {checkbox['label']}
          </label>
        ))}
        
        <button className={cN(`btn`, `btn-primary`, s.reset, s['reset-confirm-button'])} onClick={this.closePopup}>{ this.props.confirmLabel || t('shared.confirm') }</button>
        <button className={cN(`btn`, `btn-secondary`, s['reset-confirm-button'])} onClick={this.resetAndClose}>{ t('shared.reset') }</button>

      </Filter>
    );
  }
}

export default FilterCheckboxGroup;
