import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tooltip"
export default class extends Controller {
  static values = {
    sanitize: { type: Boolean, default: true }
  };

  connect() {
    // TODO update bootstrap & remove jquery
    $(this.element).tooltip({
      container: 'body',
      placement: 'right',
      html: true,
      sanitize: this.sanitizeValue,
      delay: { 'show': 0, 'hide': 700 }
    });
  }
}
