import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="css"
export default class extends Controller {
  static classes = [ 'target' ];
  static targets = [ 'content' ];

  connect() {
  }

  toggle() {
    this.contentTargets.forEach((t) => t.classList.toggle(this.targetClass));
  }

  add() {
    this.contentTargets.forEach((t) => t.classList.add(this.targetClass));
  }

  remove() {
    this.contentTargets.forEach((t) => t.classList.remove(this.targetClass));
  }
}
