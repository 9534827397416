import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="visit"
export default class extends Controller {
  static values = {
    href: String
  }
  static targets = [ 'parameter' ]

  fetch() {
    fetch(this.href());
  }

  href() {
    let queryString = this.queryString();
    return queryString.length ? `${this.hrefValue}?${queryString}` : this.hrefValue;
  }

  queryString() {
    let params = {}
    this.parameterTargets.forEach(target => {
      params[target.name] = target.value
    });
    return (new URLSearchParams(params)).toString();
  }
}
