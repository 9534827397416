import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="file"
export default class extends Controller {
  static targets = [ 'input' ];

  connect() {
  }

  checkFileCount(event) {
    const maxNumOfFiles = Number(event.params['maxNumOfFiles']);
    let resultEvent;

    if (Number.isNaN(maxNumOfFiles) || this.inputTarget.files.length <= maxNumOfFiles) {
      resultEvent = new Event('fileCountOk');
    } else {
      // Clear file list of input element; otherwise the files that the user tried to upload will be
      // sent to the server on the next request.
      this.inputTarget.files = (new DataTransfer()).files;

      resultEvent = new CustomEvent(
        'error',
        {
          detail: { text: event.params['tooManyFilesErrorMsg'] },
          bubbles: true,
          cancelable: true
        }
      );
    }

    this.inputTarget.dispatchEvent(resultEvent);
  }

  checkFileSize(event) {
    const maxFileSize = Number(event.params['maxFileSize']);
    let resultEvent;
    let oneFileTooLarge = false;

    for (let file of this.inputTarget.files ) {
      if (file.size > maxFileSize) {
        oneFileTooLarge = true;
        break;
      }
    }

    if (Number.isNaN(maxFileSize) || !oneFileTooLarge) {
      resultEvent = new Event('fileSizeOk');
    } else {
      // Clear file list of input element; otherwise the files that the user tried to upload will be
      // sent to the server on the next request.
      this.inputTarget.files = (new DataTransfer()).files;
      
      resultEvent = new CustomEvent(
        'error',
        {
          detail: { text: event.params['fileTooLargeErrorMsg'] },
          bubbles: true,
          cancelable: true
        }
      );
    }

    this.inputTarget.dispatchEvent(resultEvent);
  }

  /**
   * Call drop when the drop event is fired on a dropzone.
   */
  drop(event) {
    // For many elements, the default behavior on drop is opening the file in the browser.
    event.preventDefault();
    
    if (event.dataTransfer.files.length > 0) {
      this.inputTarget.files = event.dataTransfer.files;

      // When we set inputTarget.files programmatically as above, the change event isn't fired
      // automatically.
      this.inputTarget.dispatchEvent(new Event('change'));
    }
  }

  /**
   * Call dragover when the dragover event is fired on a dropzone.
   */
  dragover(event) {
    // Call preventDefault so the drop zone can receive a drop event. Otherwise the browser may not
    // allow this because dropping things on, say, divs is usually prohibited.
    event.preventDefault();

    // Dispatch file:dragover if the user is currently dragging a file. This event may be used to
    // style the drop zone.
    const firstDataTransferItem = event.dataTransfer.items[0];
    if (firstDataTransferItem && firstDataTransferItem['kind'] == 'file') {
      this.dispatch('dragover');
    }
  }
}
