import React from 'react';
import { reduce, forEach } from 'lodash';

const SVGs = {
  logo: require('!svg-inline-loader!images/vostel-logo.svg'),
  arrow: require('!svg-inline-loader!./svg/arrow.svg'),
};

const DURATION = 1.5;
const EASE = 'linear';

class AnimatePath extends React.Component {
  constructor(props) {
    super(props);
    this.easing = props.ease || EASE;
    this.duration = props.duration || DURATION;
    this.strokeWidth = props.strokeWidth;
  }
  componentDidMount() {
    this.paths = this.svgContainer.querySelectorAll('path');
    this.animatePaths(this.paths);
  }
  render() {
    const { className, pathName } = this.props;
    const svg = SVGs[pathName];
    return (
      <div className={className} ref={c => (this.svgContainer = c)} dangerouslySetInnerHTML={{ __html: svg }} />
    );
  }
  animatePaths(paths) {
    const totalLength = reduce(
      paths,
      (length, path) => {
        return length + path.getTotalLength();
      },
      0
    );
    let delay = 0;
    forEach(paths, (path, idx) => {
      const length = path.getTotalLength();
      const duration = length / totalLength * this.duration;
      this.animatePath(path, totalLength, duration, delay);
      delay += duration;
    });
  }
  animatePath(path, totalLength, duration, delay) {
    // inspired by https://jakearchibald.com/2013/animated-line-drawing-svg/
    const length = path.getTotalLength();
    path.style.transition = path.style.WebkitTransition = 'none';
    path.style.strokeDasharray = length + ' ' + length;
    path.style.strokeDashoffset = length;
    if (this.strokeWidth) {
      path.style.strokeWidth = this.strokeWidth;
    }
    path.getBoundingClientRect();
    path.style.transition = path.style.WebkitTransition = `stroke-dashoffset ${duration}s ${this.easing} ${delay}s`;
    path.style.strokeDashoffset = '0';
  }
}

export default AnimatePath;
