import React from 'react';
import { map } from 'lodash';
import cN from 'classnames';
import s from './style.module';

class Item extends React.Component {

  constructor(props) {
    super(props);
    this.toggleFlyout = this.toggleFlyout.bind(this);
    this.menu = props.menu;
    this.menu.registerItem(this);
    this.state = {};
  }

  render() {
    const { href, text, icon, children, active } = this.props;
    return (
      <div className={s.item}>
        <a href={href} className={cN(`compenent-menu-mobile__item-link`, s.link)} onClick={this.toggleFlyout}>
          {icon && (<div className={s['icon-wrapper']}>
            <div className={s.icon} dangerouslySetInnerHTML={{ __html: icon }} />
          </div>)}
          <div className={s.label}>{text}</div>
        </a>
        {children && (
          <div
            className={cN(`compenent-menu-mobile__children-container`, s['children-container'], this.state.flyout ? null : s['children-container--hidden'])}
            style={{left: this.state.childrenContainerLeft + 'px'}}
            ref={ (elm) => this.childrenContainerElement = elm }
          >
            <div className={s['children-container__arrow']} style={{left: this.state.arrayLeft + 'px'}}></div>
            <ul className={s['children-list']}>
              {map(children, (child, idx) => (
                <li className={s.child} key={idx}>
                  <a href={child.href} className={cN(`compenent-menu-mobile__child-link`, s['child-link'], child.active ? s['child-link--active'] : null)} dangerouslySetInnerHTML={{ __html: child.text }} />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }

  toggleFlyout() {
    this.setState({
      flyout: !this.state.flyout,
    });
    if (!this.state.flyout) {
      this.menu.itemToggled(this);
    }
  }

  closeFlyout() {
    this.setState({
      flyout: false,
    });
  }
}

export default Item;
