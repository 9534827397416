import React from 'react';
import cN from 'classnames';
import s from './style.module';
import onIcon from '!svg-inline-loader!./on.svg';
import offIcon from '!svg-inline-loader!./off.svg';

export default class Switch extends React.Component {

  constructor(props) {
    super(props);
    this.id = props.id;
    this.name = props.name;
    this.onLabel = props.on_label;
    this.offLabel = props.off_label;
    this.onValue = props.on_value;
    this.offValue = props.off_value;
    this.onChangeUrl = props.on_change_url;
    this.setWrapperElement = element => {
      this.wrapperElement = element;
    };
    this.onClick = this.onClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.state = { value: props.value !== undefined ? props.value : this.onValue };
  }

  render() {
    return (
      <div id={this.id} ref={this.setWrapperElement}>
        <div className={s['switch-wrapper']}>
          <input type="radio" name={this.name} id={this.id + '-on'} className={s.radio} onChange={this.onChange} value={this.onValue} />
          <input type="radio" name={this.name} id={this.id + '-off'} className={s.radio} onChange={this.onChange} value={this.offValue} />
          <label id={this.id + '-on-label'} htmlFor={this.id + '-on'} className={s.label}>{this.onLabel}</label>
          <a href="#" className={`${cN(s.switch, s['switch--' + (this.onValue == this.state.value ? 'on' : 'off')])} component-switch component-switch--${this.onValue == this.state.value ? 'on' : 'off'}`} onClick={this.onClick}>
            <div className={cN(s.dot, s['dot--' + (this.onValue == this.state.value ? 'on' : 'off')])}>
              <span className={cN(s.icon, s['icon--on'])} dangerouslySetInnerHTML={{ __html: onIcon }} />
              <span className={cN(s.icon, s['icon--off'])} dangerouslySetInnerHTML={{ __html: offIcon }} />
            </div>
          </a>
          <label id={this.id + '-off-label'}  htmlFor={this.id + '-off'} className={s.label}>{this.offLabel}</label>
        </div>
      </div>
    );
  }

  onClick(event) {
    event.preventDefault();
    this.updateState(this.onValue == this.state.value ? this.offValue : this.onValue);
  }

  onChange(e) {
    this.updateState(e.currentTarget.value);
  }

  updateState(value) {
    var token = $('meta[name="csrf-token"]').attr('content');
    var data = {};
    data[this.name] = value;
    $.post(this.onChangeUrl, data, function(data) {
      // response handling
    });
    this.setState({
      value: value,
    });
    $(this.wrapperElement).trigger('changeSelection', value);
  }
}
