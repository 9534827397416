import React from 'react';
import FilterBar from '../FilterBar';
import FilterCheckboxGroup from '../FilterCheckboxGroup';
import FilterDateRange from '../FilterDateRange';
import { setLocale, i18n, t, setQueryParams } from '../helpers'
import locationMarker from '!svg-inline-loader!images/icons/location_marker_transparent_circle.svg';
import companyIcon from '!svg-inline-loader!images/icons/filter_company.svg';

class FilterBarStatistics extends React.Component {
  constructor(props) {
    super(props);
    setLocale(this.props.locale);
    this.bindMethodsToThis();
    this.setPredefinedDateRanges();

    this.pickerMaxDate = new Date();
    // flatpickr doesn't allow selection of today if the time of pickerMaxDate is after the current time.
    // To avoid problems with timezones when calculating end of day, I set pickerMaxDate to (now + 1h).
    this.pickerMaxDate.setHours(this.pickerMaxDate.getHours() + 1);
  }

  bindMethodsToThis() {
    this.onCompaniesConfirm = this.onCompaniesConfirm.bind(this);
    this.onDateRangeConfirm = this.onDateRangeConfirm.bind(this);
    this.onLocationsConfirm = this.onLocationsConfirm.bind(this);
    this.onDepartmentsConfirm = this.onDepartmentsConfirm.bind(this);
  }

  setPredefinedDateRanges() {
    const today = new Date();
    const todayYear = today.getFullYear();
    const todayMonth = today.getMonth();

    const lastYear = {
      from: new Date(todayYear - 1, 0, 1),
      to: new Date(todayYear - 1, 11, 31, 23, 59, 59) 
    }
    const lastMonth = {
      from: new Date(todayYear, todayMonth - 1, 1),
      to: new Date(todayYear, todayMonth, 0, 23, 59, 59)
    }

    const lastSunday = new Date();
    lastSunday.setDate(lastSunday.getDate() - lastSunday.getDay());
    const beginOfLastWeek = new Date(lastSunday.getTime());
    beginOfLastWeek.setDate(beginOfLastWeek.getDate() - 6);

    this.predefinedDateRanges = [
      {
        label: t('statistics_filters.last_year'),
        fromDateString: lastYear.from.toDateString(),
        toDateString: lastYear.to.toDateString()
      },
      {
        label: t('statistics_filters.last_month'),
        fromDateString: lastMonth.from.toDateString(),
        toDateString: lastMonth.to.toDateString()
      },
      {
        label: t('statistics_filters.last_week'),
        fromDateString: beginOfLastWeek.toDateString(),
        toDateString: lastSunday.toDateString()
      },
      {
        label: t('statistics_filters.this_year'),
        fromDateString: new Date(todayYear, 0, 1).toDateString(),
        toDateString: today.toDateString()
      }
    ];
  }

  onDateRangeConfirm(fromDate, toDate) {
    const fromDateStr = fromDate ? i18n.l('time.formats.date_technical', fromDate) : null;
    const toDateStr = toDate ? i18n.l('time.formats.date_technical', toDate) : null;
    setQueryParams({ 'from_date': fromDateStr, 'to_date': toDateStr });
  }

  onCompaniesConfirm(values) {
    let params = {'companies': values};
    if (values.length != 1) {
      // Now, there is no company or more than one selected.
      // If locations have been selected, delete the selection.
      params['locations'] = [null];
      params['departments'] = [null];
    }
    setQueryParams(params);
  }

  onDepartmentsConfirm(values) {
    setQueryParams({'departments': values});
  }

  onLocationsConfirm(values) {
    setQueryParams({'locations': values});
  }

  render() {
    return (
      <FilterBar>
        <FilterDateRange
          onDateRangeConfirm={this.onDateRangeConfirm}
          defaultFromDate={this.props.defaultFromDate}
          defaultToDate={this.props.defaultToDate}
          predefinedDateRanges={this.predefinedDateRanges}
          pickerOptions={{ maxDate: this.pickerMaxDate }}
          inactiveLabel={t('statistics_filters.whole_time_span')}
        />
        { this.props.allCompanies && this.props.allCompanies.length > 0 && (
          <FilterCheckboxGroup
            inactiveLabel={t('statistics_filters.all_companies')}
            activeLabel={t('statistics_filters.companies')}
            icon={companyIcon}
            checkboxes={this.props.allCompanies}
            defaultValue={this.props.selectedCompanies || []}
            onConfirm={this.onCompaniesConfirm}
          />
        )}
        { this.props.allLocations && this.props.allLocations.length > 0 && (
          <FilterCheckboxGroup
            icon={locationMarker}
            inactiveLabel={t('statistics_filters.all_locations')}
            activeLabel={t('statistics_filters.locations')}
            checkboxes={this.props.allLocations}
            defaultValue={this.props.selectedLocations || []}
            onConfirm={this.onLocationsConfirm}
          />
        )}
        { this.props.allDepartments && this.props.allDepartments.length > 0 && (
          <FilterCheckboxGroup
            inactiveLabel={t('statistics_filters.all_departments')}
            activeLabel={t('statistics_filters.departments')}
            checkboxes={this.props.allDepartments}
            defaultValue={this.props.selectedDepartments || []}
            onConfirm={this.onDepartmentsConfirm}
          />
        )}
      </FilterBar>
    );
  }
}

export default FilterBarStatistics;
