import React from 'react';

export const transparentButtonState = {
  ACTIVE: 'active',
  OPEN: 'open',
  DEFAULT: 'default'
};

class TransparentButton extends React.Component {
  constructor(props) {
    super(props);
  }

  stateClass() {
    switch(this.props.state) {
      case transparentButtonState.ACTIVE:
        return 'filter--active';
      case transparentButtonState.OPEN:
        return 'filter--open';
      default:
        return null;
    }
  }

  render() {
    let icon = this.props.icon;

    return (
      <button className={`filter ${this.stateClass() || ''} ${this.props.className}`} onClick={this.props.onClick} ref={this.props.buttonRef}>
        {icon && (<span aria-hidden="true" className={'svg-icon'} dangerouslySetInnerHTML={{ __html: icon }} />)}
        {this.props.children}
      </button>
    );
  }
}

export default TransparentButton;
