import React from 'react';
import cN from 'classnames';
import s from './style.module';
import Filter from '../Filter';
import TransparentButton, { transparentButtonState } from '../TransparentButton';
import DateRangePicker from '../DateRangePicker';
import dateIcon from '!svg-inline-loader!images/icons/filter_date.svg';
import { i18n, t } from '../helpers';

/**
 * FilterDateRange has a popup with a calendar and notifies the parent component via a callback when the
 * user has selected a date range.
 * 
 * props:
 * 
 * - defaultFromDate (string), defaultToDate (string): preselected date range. Both props are used as
 * arguments for Date.parse.
 * 
 * - inactiveLabel (string): Label of the filter button when no date range is selected.
 * 
 * - onDateRangeConfirm: Function with two arguments (fromDate and toDate). Is called when the user has
 * selected a date range. The arguments are beginning and end of the selected range as Date objects.
 * 
 * - pickerOptions: Object with options for the flatpickr plugin that is used as calendar.
* 
 * - predefinedDateRanges: An array like:
 * [
 *  {
 *    label: 'an hour ago',
 *    fromDateString: ourAgo.toDateString(),
 *    toDateString: now.toDateString()
 *  }
 * ]
 * For each element, the component adds a button that selects the given date range in the picker.
 * fromDateString and toDateString must be strings like those given by Date.toDateString() so that comparison
 * without consideration of time is easier.
 */
class FilterDateRange extends React.Component {
  constructor(props) {
    super(props);
    this.bindMethodsToThis();
    this.state = {
      dateRange: [null, null],
      dateStr: null,
      predefinedDateRangeIndex: -1
    };
    this.filterRef = React.createRef();
    this.pickerRef = React.createRef();
    this.setDefaultDateRange();
  }

  bindMethodsToThis() {
    this.onPopupClose = this.onPopupClose.bind(this);
    this.onPopupOpen = this.onPopupOpen.bind(this);
    this.onDatePickerChange = this.onDatePickerChange.bind(this);
    this.resetDateAndClose = this.resetDateAndClose.bind(this);
    this.togglePredefinedDateRange = this.togglePredefinedDateRange.bind(this);
  }

  /**
   * Sets this.state.dateRange, this.state.dateStr, and this.predefinedDateRangeIndex
   * according to this.props.defaultFromDate and this.props.defaultToDate.
   * The method should be called in the constructor.
   */
  setDefaultDateRange() {
    const defaultFromDateTimestamp = Date.parse(this.props.defaultFromDate)
    const defaultToDateTimestamp = Date.parse(this.props.defaultToDate)

    if (defaultFromDateTimestamp && defaultToDateTimestamp) {
      this.defaultFromDate = new Date(defaultFromDateTimestamp);
      this.defaultToDate = new Date(defaultToDateTimestamp);
      
      // The method is called by the constructor and hence doesn't use setState.
      Object.assign(this.state, {
        dateRange: [this.defaultFromDate, this.defaultToDate],
        dateStr: this.dateRangeToString(this.defaultFromDate,this.defaultToDate),
        predefinedDateRangeIndex: this.findPredefinedDateRangeIndex(this.defaultFromDate, this.defaultToDate)
      });
    }
  }

  dateRangeToString(fromDate, toDate) {
    if (!fromDate || !toDate) {
      return null;
    }
    const fromDateStr = i18n.l('date.formats.date', fromDate);
    const toDateStr = i18n.l('date.formats.date', toDate);
    return (fromDateStr == toDateStr) ? fromDateStr : `${fromDateStr} - ${toDateStr}`;
  }

  findPredefinedDateRangeIndex(fromDate, toDate) {
    if (!this.props.predefinedDateRanges || !fromDate || !toDate) {
      return -1;
    }
    return this.props.predefinedDateRanges.findIndex(
      (predefinedDateRange) => predefinedDateRange.fromDateString == fromDate.toDateString() && predefinedDateRange.toDateString == toDate.toDateString()
    );
  }

  togglePredefinedDateRange(predefinedDateRangeIndex) {
    if (this.dateRangeSelected() && this.state.predefinedDateRangeIndex == predefinedDateRangeIndex) {
      // The clicked date range for the clicked button is already selected.
      this.resetDate();
    } else {
      const predefinedDateRange = this.props.predefinedDateRanges[predefinedDateRangeIndex]
      this.setDateRangeAndClose(new Date(predefinedDateRange.fromDateString), new Date(predefinedDateRange.toDateString));
    }
  }

  /**
   * closePopup() calls the closePopup method of the Filter component, which triggers
   * onPopupClose as callback. (The reason for this is that the Filter component may close
   * the popup itself.) Hence any code that must be executed when the popup is closed
   * should be placed in onPopupClose.
   */
  closePopup() {
    this.filterRef.current.closePopup();
  }

  confirmDateRange() {
    this.props.onDateRangeConfirm.call(this, ...this.state.dateRange);
  }

  dateRangeSelected() {
    return this.state.dateRange[0] != null && this.state.dateRange[1] != null
  }

  /**
   * 
   * @param {Date} fromDate 
   * @param {Date} toDate 
   */
  setDateRange(fromDate, toDate, callback) {
    this.setState({
      dateRange: [fromDate, toDate],
      dateStr: this.dateRangeToString(fromDate, toDate),
      predefinedDateRangeIndex: this.findPredefinedDateRangeIndex(fromDate, toDate)
    }, callback);
  }

  setDateRangeAndClose(fromDate, toDate) {
    this.setDateRange(fromDate, toDate, function() {
      this.confirmDateRange();
      this.closePopup(); 
    });
  }

  resetDate(callback) {
    this.setDateRange(null, null, callback);
    this.pickerRef.current.flatpickr.setDate(null, null);
    this.pickerRef.current.flatpickr.jumpToDate(new Date());
  }

  resetDateAndClose() {
    this.resetDate(function() {
      this.confirmDateRange();
      this.closePopup(); 
    });
  }

  /**
   * onDatePickerChange is called by the flatpickr plugin.
   */
  onDatePickerChange(selectedDates, dateStr, instance) {
    if (Array.isArray(selectedDates) && selectedDates.length == 2) {
      // Range selected
      this.setDateRangeAndClose(selectedDates[0], selectedDates[1]);
    } else {
      this.setState({ predefinedDateRangeIndex: -1 });
    }
  }

  /**
   * onPopupClose is called by Filter component when the popup is closed
   * Cf. closePopup().
   */
  onPopupClose() {
    if (this.state.dateRange[0] != this.defaultFromDate || this.state.dateRange[1] != this.defaultToDate) {
      this.confirmDateRange();
    } else {
      // If the user started to select a date and then closed the popup,
      // flatpickr should forget the selection.
      this.pickerRef.current.flatpickr.setDate(this.state.dateRange);
    }
  }

  /**
   * onPopupOpen is called by Filter component when the popup is opened.
   */
  onPopupOpen() {
    this.pickerRef.current.flatpickr.jumpToDate(this.dateRangeSelected() ? this.state.dateRange[1] : new Date());
  }

  predefinedDateRangeButtonState(buttonPredefinedDateRangeIndex) {
    return this.state.predefinedDateRangeIndex == buttonPredefinedDateRangeIndex ? transparentButtonState.ACTIVE : transparentButtonState.DEFAULT;
  }

  render() {
    return (
      <Filter icon={dateIcon} label={this.state.dateStr || this.props.inactiveLabel} filterButtonActive={this.dateRangeSelected()} ref={this.filterRef} onPopupClose={this.onPopupClose} onPopupOpen={this.onPopupOpen}>

        { this.props.predefinedDateRanges && this.props.predefinedDateRanges.length && (
          <div className={s['range-button-group']}>
            { this.props.predefinedDateRanges.map((predefinedDateRange, index) => (
                <TransparentButton key={predefinedDateRange.label} onClick={e => this.togglePredefinedDateRange(index)} state={this.predefinedDateRangeButtonState(index)}>
                  {predefinedDateRange.label}
                </TransparentButton>
              )) }
          </div>
        )}

        <DateRangePicker
          onDatePickerChange={this.onDatePickerChange}
          value={this.state.dateRange}
          pickerOptions={this.props.pickerOptions}
          ref={this.pickerRef}
        />

        <button className={cN(`btn`, `btn-secondary`, s['reset-button'])} onClick={this.resetDateAndClose}>{ t('shared.reset') }</button>

      </Filter>
    );
  }
}

export default FilterDateRange;
