import React from 'react';
import { reduce, map, pickBy, flow } from 'lodash';

const PREFIX = 'w';

export default function({ image, className, sizes, alt }) {
    const resolutions = flow(
        resolutions => pickBy(resolutions, (r, key) => key[0] === PREFIX),
        resolutions => map(resolutions, (r, key) => key.substring(1, key.length))
    )(image);

    const multipleSRC = reduce(
        resolutions,
        (string, width, index) => {
            if (image[PREFIX + width]) {
                return (
                    string +
                    image[PREFIX + width].url +
                    ' ' +
                    width +
                    'w' +
                    (index !== resolutions.length - 1 ? ', ' : '')
                );
            }
            return string;
        },
        ''
    );

    return <img className={className} alt={alt} src={image[PREFIX + resolutions[1]].url} srcSet={multipleSRC} sizes={sizes} />;
}
