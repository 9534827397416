import { Controller } from "@hotwired/stimulus"

/**
 * The set() function checks whether the content of the "overflow" target is bigger than the target
 * itself. If it is, the "overflown" is added to this, and "notOverflown" is removed. If it is not,
 * vice versa.
 * 
 * Connects to data-controller="set-by-overflow"
 */
export default class extends Controller {
  static targets = [ 'overflow' ];
  static classes = [ 'overflown', 'notOverflown' ];
  static values = { overflown: Boolean };

  connect() {
    this.set();
  }

  overflownValueChanged() {
    let element = this.context.element;

    if (this.overflownValue) {
      if (this.hasOverflownClass) {
        element.classList.add(this.overflownClass);
      }
      if (this.hasNotOverflownClass) {
        element.classList.remove(this.notOverflownClass);
      }
    } else {
      if (this.hasOverflownClass) {
        element.classList.remove(this.overflownClass);
      }
      if (this.hasNotOverflownClass) {
        element.classList.add(this.notOverflownClass);
      }
    }
  }

  set() {
    this.overflownValue = (this.overflowTarget.clientWidth < this.overflowTarget.scrollWidth);
  }
}
