import React from 'react';
import { map } from 'lodash';
import s from './style.module';
import Project from 'components/Project';
import { setLocale } from '../helpers';

class ProjectList extends React.Component {
  constructor(props) {
    super(props);
    this.projectEventHandler = new ProjectEventHandler();
    setLocale(this.props.locale);
  }
  render() {
    const { projects, processTypeLabels, locationIndependentLabel, frequencyLabels } = this.props;
    return (
      <ul className={s.container}>
        {map(projects, (project, idx) => (
          <li className={s.project} key={idx}>
            <Project {...project} processTypeLabels={processTypeLabels} locationIndependentLabel={locationIndependentLabel} frequencyLabels={frequencyLabels} projectEventHandler={this.projectEventHandler} />
          </li>
        ))}
      </ul>
    );
  }
}

class ProjectEventHandler {

  constructor() {
    this.subscribers = [];
  }

  fire(event) {
    for (var i = 0; i < this.subscribers.length; i++) {
      this.subscribers[i](event);
    }
  }

  subscribe(subscriber) {
    this.subscribers.push(subscriber);
  }
}

export default ProjectList;
