import { Controller } from "@hotwired/stimulus"

// If you add controller X (call it instance A) in the scope of another instance B of controller X,
// the instance A doesn't see bubbling events that are dispatched in the scope of instance B. This
// is intentional Stimulus behavior:
// https://github.com/hotwired/stimulus/issues/363#issuecomment-757362033.
//
// So the following doesn't work:
//
//   <div data-controller="gallery" data-action="click->gallery#next">
//     <div data-controller="gallery">
//       <button>Click me!</button>
//     </div>
//   </div>
//
// You can use this controller to dispatch a copy of the event on the parent controller to
// circumvent the described restriction:
//
//   <div data-controller="gallery" data-action="click->gallery#next">
//     <div data-controller="gallery propagate" data-action="click->propagate#propagate">
//       <button>Click me!</button>
//     </div>
//   </div>

// Connects to data-controller="propagate"
export default class extends Controller {
  connect() {
  }

  propagate(event) {
    const newEvent = new event.constructor(event.type, event);
    this.element.parentNode.dispatchEvent(newEvent);
  }
}
