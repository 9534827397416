const germanLanguageSkillLevels = {
    mother_tongue: 0.9,
    advanced_knowledge: 0.6,
    medium_knowledge: 0.35,
    basic_knowledge: 0.1,
    none: 0,
    not_required: 0,
};

export { germanLanguageSkillLevels };
